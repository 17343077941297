import * as React from "react"
import { useEffect } from "react";
import { Content, PageLayout } from '@atlaskit/page-layout';
import Spinner from '@atlaskit/spinner';
import { navigate } from "gatsby";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/mainStore";
import "../styles.css";

const Auth = () => {

    const isBrowser = () => typeof window !== "undefined";
  
    useEffect(() => {
      const initialize = async () => {
        if (!isBrowser()) return;
        
        let params = new URLSearchParams(document.location.search);
        let clientKey = params.get("clientKey");
        
        if (clientKey) {
          localStorage.setItem("clientKey", clientKey);
          isBrowser() && window.open(`https://bitbucket.org/site/oauth2/authorize?client_id=${mainStore.oauthConsumerId}&response_type=code`,"_self");
        } else {
          navigate("/");
        }
      }
      initialize();    
    }, []);
  
    return (
      <PageLayout>
          <Content testId="content">
            <div style={{
              display: "flex",
              width: "100%",
              justifyItems: "center",
              justifyContent: "center" }}>
                <div><Spinner size={30} interactionName="load" /></div>
            </div>
          </Content>
        </PageLayout>
    )
  }
  
  export default observer(Auth);
  